/* Styles file */
@import 'variables.scss';
@import 'custom.scss';
@import 'node_modules/bootstrap/scss/bootstrap';
@import '../base/styles';

html,
html body {
  // font-family: $font;
  background-color: $background-primary;
  font-weight: normal;
  color: $text-color;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $font, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #cecece;
}

.delimiter {
  margin: 3.5rem auto;

  @include media-breakpoint-up(md) {
    max-width: 45rem;
    width: 100%;
    height: 0.125rem;
    position: relative;
    margin: 3.125rem auto 4.063rem;
    box-sizing: border-box;
    border: 0.0625rem solid $bottom-delimiter-color;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  @include media-breakpoint-only(lg) {
    max-width: 60rem;
  }

  @include media-breakpoint-only(xl) {
    max-width: 71.25rem;
  }
}

.bottom-delimiter {
  border-top: 1px solid $bottom-delimiter-color;
}

%invalid-input {
  border: $validation-input-border;
  background: $validation-input-background;
  color: $validation-font-color;
}

.invalid-note {
  color: $validation-text-colour;
  font-size: 0.8rem;
}

.required-fields {
  margin-bottom: 1.875rem;
  font-size: 0.75rem;
  color: $required-fields-text-color;
}

.form-control {
  border-radius: $field-border-radius;
  color: $field-color;
  font-size: $field-font-size;
  background-color: $field-background-color;
  border: $field-border-width solid $field-border-color;
  padding: 8px;

  &:disabled {
    background-color: $field-background-color;
    color: $field-disabled-text-color;
  }

  &:focus {
    background-color: $field-background-color;
    border-color: $primary;
    color: $field-color;
  }
}

.input-group-text {
  border-radius: $field-border-radius;
  background-color: $field-background-color;
  border: $field-border-width solid $field-border-color;
  color: $field-color;
  font-size: $field-font-size;

  &:disabled {
    background-color: $field-background-color;
    color: $field-disabled-text-color;
  }

  &:focus {
    background-color: $field-background-color;
    border-color: $primary;
    color: $field-color;
  }
}

.modal {
  z-index: 1100 !important;
}

// -- DROPDOWN component - Shop/History page -- 
.ct-select-dropdown {
  flex-direction: column;

  .ct-select-toggle {
    height: 40px !important;
    font-family: $font, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    border-radius: $field-border-radius !important;
    color: $field-color !important;
    background-color: $field-background-color !important;
    border: 1px solid $field-border-color !important;

    .image {
      height: 35px !important;
    }

    .label {
      color: $text-color !important;
    }

    .caret {
      color: #333 !important;
      line-height: 60px !important;
      font-size: 22px !important;
      top: 4px !important;
      right: 4px !important;

      &.caret-down:after {
        border: none !important;
        content: url($arrow-up) !important;
      }

      &.caret-up:after {
        border: none !important;
        content: url($arrow-down) !important;
      }
    }
  }

  .ct-select-menu {
    border-radius: $field-border-radius !important;
    background-color: $field-background-color !important;
    border: 1px solid $field-border-color !important;

    .select-item {
      color: $field-color !important;
    }
  }
}

// -- the end of DROPDOWN component - Shop/History page --


// -- DROPDOWN component - In-Car Topup page -- 
.iti__flag-container .iti__country-list {
  background-color: $background-primary !important;

  span.country-name {
    color: $text-color !important;
  }
}

// -- the end of DROPDOWN component - In-Car Topup page --

// -- BEGIN INFO-POPOVER -- 
.info-popover {
  float: none;
  margin-left: 38px;
  padding: 1px 7.5px;
  border-radius: 50%;
  font-size: 14px;
  color: $white;
  background: $primary;
  font-style: normal;
  user-select: none;
}

.popover-title {
  background: $white;
  border: none;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.popover-text {
  font-size: 12px;
  padding-left: 15px;
}

.popover-list {
  padding: 0;
  list-style: none;

  &.dashed-list {
    padding-left: 10px;

    .popover-item {
      text-indent: 0em;


      &::before {
        content: " ";

      }
    }
  }

  .popover-item {
    font-size: 12px;
    margin: 10px;
  }
}

// -- END INFO-POPOVER --
.visible.spinner.center {
  .lds-roller {
    left: 50%;
    margin-left: -32px;
    top: 50%;
    margin-top: -32px;
  }
}

.iti {
  display: block;
}

.intl-tel-input .selected-flag .iti-arrow {
  border-top-color: $border-color;
}

.switch:focus {
  outline: none;
}

.switch.checked {
  background: $primary;
}

.table {
  color: $text-color !important;
}

.logo {
  overflow: hidden;
  max-width: 10.375rem;
  max-height: 1.5rem;
  background-image: url($car-brand-logo);
  background-size: contain;

  background-repeat: no-repeat;

  @include media-breakpoint-up(lg) {
    margin-top: $logo-margin-top;
    max-width: 14rem;
    max-height: 2.80rem;
  }
}

.custom-bullet-icon {
  /// arrow
  content: "";
  border-right: 1px solid $text-color;
  border-bottom: 1px solid $text-color;
  width: 5px;
  height: 5px;
  transform: rotate(-45deg);
  float: left;
  margin-top: 8px;
  margin-left: -17px;
}

.modal-content {
  background-color: $background-primary !important;
}

.circle {
  &-light {
    fill: $icon-circle-light;
    color: $icon-circle-light;
  }

  &-dark {
    fill: $icon-circle-dark;
    color: $icon-circle-dark;
  }
}

.dropdown-item {

  &.active,
  &:active {
    background-color: #e9ecef;
    color: inherit;
  }
}

.nav-link {
  color: $text-color;

  &:hover {
    color: $primary;
  }
}

.alert {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  color: $text-color !important;

  a {
    color: inherit !important;

  }
}

.alert-danger {
  background-color: $danger-color-background;
  border-color: $danger-color-border;
}

.alert-success {
  background-color: $success-color-background;
  border-color: $success-color-border;
}

.alert-warning {
  background-color: $warning-color-background;
  border-color: $warning-color-border;
}

.alert-info {
  background-color: $info-color-background;
  border-color: $info-color-border;
}

.or-divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $border-color;

  &::before {
    content: '';
    height: 1px;
    background: $border-color;
    flex: 1;
    margin-right: 8px;
  }

  &::after {
    content: '';
    height: 1px;
    background: $border-color;
    flex: 1;
    margin-left: 8px
  }
}

.btn {
  min-width: 120px;
}

.ngb-dp-arrow-btn {
  min-width: 0 !important;
}


::ng-deep .accordion {
  & > .card:not(:last-of-type),
  & > .accordion-item:not(:last-of-type) {
      border-bottom: 1px solid $field-border-color;
  }

  // be in mind that there's some compile issue that is creating 
  // differents css class name for chrome and safari
  .card,
  .accordion-item {
      border: 0px;
      border-bottom: 1px solid $field-border-color;
      
      .card-header,
      .accordion-header {
          padding: 0px;
          background-color: transparent;
          border: 0px;

          .accordion-btn {
              background: transparent;
              color: $text-color;
              border: 0px;
              padding: 0px;
              font: inherit;
              cursor: pointer;
              outline: inherit;
          }

          .accordion-icon {
              transition: all .2s ease;
              color: $text-color !important;

              &.rotated {
                  transform: rotate(180deg);
                  -webkit-transform: rotate(180deg);
                  -ms-transform: rotate(180deg);
                  -o-transform: rotate(180deg);
              }
          }
      }

      .card-body,
      .accordion-body {
          padding: 0px;  
      }
  }
}
