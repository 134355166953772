$primary: #058994;
$secondary: #1F2937;

$text-color: #4B5563;

$white: #ffffff;
$black: #000000;

$border-color: #DCDCDC;

$background-primary: $white;
$background-secondary: #F2F2F2;

$background-disabled: #d8d8d8;
$text-disabled-color: #9e9fa0;

$success-color-border: #37D002;
$info-color-border: #2845E8;
$warning-color-border: #AB8A13;
$danger-color-border: #E82837;

$success-color-background: #F4F9EB;
$info-color-background: #E6EEFF;
$warning-color-background: #FEF9E8;
$danger-color-background: #FFF2F0;