@import "variables.scss";

.standard-link {
    @extend .text-primary;
}

.dark-link {
    color: $text-color !important;
}

a {
    text-decoration: none !important;
}