@import 'variables.scss';
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "light": $white,
    "dark": $black,
    "success": $success-color-border,
    "info": $info-color-border,
    "warning": $warning-color-border,
    "danger": $danger-color-border
);

.image-container {
    mix-blend-mode: multiply !important;
}