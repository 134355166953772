@import 'node_modules/bootstrap/scss/bootstrap-grid.scss';

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

h1 {
  font-size: 1.91rem !important;

  @include media-breakpoint-only(md) {
    font-size: 2.31rem !important;
  }

  @include media-breakpoint-only(lg) {
    font-size: 3rem !important;
  }

  @include media-breakpoint-only(xl) {
    font-size: 3.625rem !important;
  }
}

h2 {
  font-size: 1.8rem !important;

  @include media-breakpoint-only(md) {
    font-size: 2.25rem !important;
  }

  @include media-breakpoint-up(lg) {
    font-size: 3rem !important;
  }
}

p {
  font-size: 1.05rem;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}